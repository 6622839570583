<div class="flex flex-col" [formGroup]="formGroup">
    <div class="mat-subtitle-2">
        {{ setting.title | translate }}
    </div>
    @switch (setting.type) {
        @case (types.Toggle) {
            <mat-slide-toggle
                formControlName="setting"
                color="primary"
                [style.opacity]="setting.disabled ? 0.75 : 1"
            >
                {{
                    (formGroup.get('setting').value
                        ? setting?.config?.onLabel
                        : setting?.config?.offLabel
                    ) | translate
                }}
            </mat-slide-toggle>
        }
        @case (types.Select) {
            <sz-select
                formControlName="setting"
                class="w-[270px]"
                [isMultiple]="setting?.config?.isMultiple"
                [options]="setting?.config?.options"
            />
        }
        @case (types.Color) {
            <ngx-colors
                *ngIf="!setting.disabled; else coloredCircle"
                formControlName="setting"
                ngx-colors-trigger
                colorPickerControls="no-alpha"
                format="hex"
                overlayClassName="mat-typography"
                [palette]="palette"
            ></ngx-colors>
            <ng-template #coloredCircle>
                <div
                    class="h-6 w-6 rounded-full"
                    [style.background-color]="formGroup.get('setting').value"
                ></div>
            </ng-template>
        }
        @case (types.Image) {
            <sz-image-picker
                [defaultPath]="setting.default"
                [disabled]="setting.disabled"
                [faviconCustomerId]="faviconCustomerId"
                [path]="formGroup.get('setting').value"
            />
        }
        @case (types.Model) {
            <ng-container *ngTemplateOutlet="entityButton" />
        }
        @case (types.Organization) {
            <ng-container *ngTemplateOutlet="entityButton" />
        }
        @default {
            <sz-input
                formControlName="setting"
                class="w-[270px]"
                [errorMessages]="errorMessages"
                [type]="setting?.config?.type"
            />
        }
    }
    <div class="mt-0.5">
        {{ setting.description | translate: setting.descriptionParams }}
    </div>
</div>

<ng-template #entityButton>
    <button
        class="flow-row flex w-[270px] h-10 items-center justify-center gap-4 rounded-lg bg-gray-200 p-2 outline-none hover:bg-gray-300 focus:bg-gray-300 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:bg-gray-700"
        (click)="changeEntity()"
    >
        <mat-icon
            svgIcon="{{
                setting.type === types.Model
                    ? 'book-variant-multiple'
                    : 'shield-account'
            }}"
        ></mat-icon>
        <span class="overflow-ellipsis">
            {{ entity?.name ?? 'select.selected.none' | translate }}
        </span>
    </button>
</ng-template>
